import requests from '../requests'

export default {
  create_sales_order_from_quotes(project, o) {
    return requests.post(`projects/${project.id}/finance/sales-orders/from-quotes`, o)
  },
  issue_purchase_order(project, o) {
    return requests.post(`projects/${project.id}/finance/purchase-orders`, o)
  },
  issue_sales_order(project, o) {
    return requests.post(`projects/${project.id}/finance/sales-orders`, o)
  },
  costing: {
    list(project) {
      return requests.get(`projects/${project.id}/finance/costing`)
    }
  },
  budget: {
    get(project) {
      return requests.get(`projects/${project.id}/finance/budget`)
    },
    set(project, budget) {
      return requests.put(`projects/${project.id}/finance/budget`, { budget })
    },
    analysis(project) {
      return requests.get(`projects/${project.id}/finance/budget/analysis`)
    }
  },
  paydocs: {
    list(project, filter) {
      const filterQueryParts = []

      for (const key in filter) {
        filterQueryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`)
      }

      const filterQuery = `?${filterQueryParts.join('&')}`
      return requests.get(`projects/${project.id}/finance/paydocs${filterQuery}`)
    }
  }
}
