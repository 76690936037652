<template>
  <component v-if="layoutName" :is="layout">
    <div class="underlay" v-if="dev">
      <div>Development</div>
    </div>
    <slot />
  </component>
</template>

<style scoped>
.underlay {
  color: #ddd;
  font-size: 15rem;
  z-index: -1000;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}
.underlay > div {
  transform: rotate(+45deg);
  text-shadow: 0 0 10px rgba(0,0,0,0.5);
}
</style>

<script>
import { mapGetters } from 'vuex'

const defaultLayout = 'DefaultLayout'

export default {
  data: () => ({
    layoutName: null
  }),
  watch: {
    '$route' (incomingRoute) {
      this.checkRouteLayout(incomingRoute)
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    dev () {
      return this.user && this.user.dev
    },
    layout () {
      console.log('Switching to:', this.layoutName)
      return () => import(`./layouts/${this.layoutName}.vue`)
    }
  },
  methods: {
    checkRouteLayout (incomingRoute) {
      const applicableRoute = incomingRoute.matched.find(r => r.meta.layout)
      const selectedRoute = applicableRoute === undefined ? defaultLayout : applicableRoute.meta.layout

      this.layoutName = selectedRoute
    }
  },
  mounted () {
    this.checkRouteLayout(this.$route)
  }
}
</script>
