<template>
  <AppLayout>
    <router-view v-if="ready" />
    <template v-else>
      <p class="lead">
        <icon-text icon="spinner" spin><strong>Arc</strong> is loading. Please stand by...</icon-text>
      </p>
      <hr>
      <p class="text-muted"><small>{{ progress }}</small></p>
    </template>
  </AppLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import AppLayout from './AppLayout.vue'

export default {
  data: () => ({
    ready: false,
    user: null,
    info: null
  }),
  components: { AppLayout },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      progress: 'auth/authProgress'
    }),
    busy() {
      return false
    }
  },
  async mounted() {
    // Initialise the authentication subsystem
    await this.$store.dispatch('auth/initialise')

    const meta = { authPolicy: 'default' }
    for (const matchedRoute of this.$route.matched) {
      Object.assign(meta, matchedRoute.meta)
    }

    if (!this.isLoggedIn && meta.authPolicy === 'default') {
      /* eslint-disable no-undef-init */
      let hash = undefined
      if (window.location.pathname !== '/login') {
        hash = `#redirect_to=${encodeURIComponent(window.location.pathname)}`
      }

      // If we're NOT logged in, and the route DOES require authentication,
      // redirect to the 'Login' route, and mark us as ready.
      this.$router.push({ name: 'Login', hash }, async () => {
        this.ready = true
      })
    } else if (this.isLoggedIn && this.$route.name === 'Login') {
      // If we ARE logged in, and the route is the Login route,
      // redirect to the 'Dashboard' route, and mark us as ready.
      this.$router.push({ name: 'Dashboard' }, async () => {
        this.ready = true
      })
    } else {
      // Otherwise, we're ready to go!
      this.ready = true
    }
  }
}
</script>
