import requests from '../requests'
import contacts from './contacts'

export default {
  list () {
    return requests.get('companies')
  },
  create (o) {
    return requests.post('companies', o)
  },
  get (id) {
    return requests.get(`companies/${id}`)
  },
  delete (c) {
    return requests.delete(`companies/${c.id}`)
  },
  suppliers () {
    return requests.get('suppliers')
  },
  clients () {
    return requests.get('clients')
  },
  contacts,
  cl: {
    get (id) {
      return requests.get(`clients/${id}`)
    }
  },
  delete_supplier_record (c) {
    return requests.delete(`companies/${c.id}/supplier`)
  },
  delete_client_record (c) {
    return requests.delete(`companies/${c.id}/client`)
  },
  delete_freelancer_record (c) {
    return requests.delete(`companies/${c.id}/freelancer`)
  },
  delete_supported_venue_record (c) {
    return requests.delete(`companies/${c.id}/supported-venue`)
  },
  links: {
    xero: {
      add (c, o) {
        return requests.post(`companies/${c.id}/links/xero`, o)
      },
      remove (c) {
        return requests.delete(`companies/${c.id}/links/xero`)
      },
      list_contacts (c) {
        return requests.get(`companies/${c.id}/links/xero/contacts`)
      }
    }
  }
}
